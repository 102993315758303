//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import featuredProducts from '~/graphql/queries/products/featured';
import productsTrending from '~/graphql/queries/products/trending';
import productsRecent from '~/graphql/queries/products/recent';
import indexStats from '~/graphql/queries/indexStats';

export default {
  auth: false,
  layout: 'withSidebar',
  apollo: {
    highlights: {
      query: featuredProducts,
      update(data) {
        return data.featuredProducts;
      },
      skip() {
        return process.server;
      },
    },
    trending: {
      query: productsTrending,
      update(data) {
        return data.trendingProducts;
      },
      skip() {
        return process.server;
      },
    },
    recentAdded: {
      query: productsRecent,
      update(data) {
        return data.recentProducts;
      },
      skip() {
        return process.server;
      },
    },
    iconStats: {
      query: indexStats,
      update(data) {
        return data.indexStats;
      },
    },
  },
  data: () => ({
    iconStats: [],
    highlights: [],
    trending: [],
    recentAdded: [],
  }),
};
