//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {OPEN_SECTOR_CODES} from '~/constants/constants';

export default {
  props: {
    icon: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    explanation: {
      type: String,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    info() {
      return `<div
        class="relative bg-white rounded-md border border-grey-light shadow-lg px-6 py-4 z-10"
        style="max-width: 12rem;"
      >
        <h3 class="text-left text-primary font-semibold pb-3">${this.title}</h3>
        <div class="text-left">
          ${this.explanation}
        </div>
      </div>`;
    },
  },
  methods: {
    toggleInfo() {
      this.info = !this.info;
    },
    action() {
      if (this.clickable) {
        this.$nuxt.$emit(OPEN_SECTOR_CODES);
      }
    },
  },
};
